import React, { Suspense, useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import './App.css';
import Sidebar from './component/common/Sidebar';
import { CommonContextProvider } from './context/CommonContext';
import Pageloader from './component/common/Pageloader';
import CustomTheme from './component/common/CustomTheme';
import Merchant from './component/merchant';
import PickupPointsView from './component/pickupPoints';
import ShipmentsView from './component/shipments';
import CourierView from './component/couriers';
import PickupView from './component/pickup';
import UsersView from './component/users';
import DownloadView from './component/download';
import DashboardView from './component/dashboard';
import SiteSetting from './component/siteSetting';
import ServiceabilityView from './component/serviceability';
import 'rsuite/dist/rsuite.css';
import NotFound from './component/common/NotFound ';
import PricingView from './component/pricing';
import WeightScreenView from './component/weight';
import FreightView from './component/freight';
import InvoiceView from './component/invoice';
import NdrView from './component/ndr';
import Footer from './component/common/Footer';


const AdminSignIn = React.lazy(() => import('./component/common/AdminSignIn'));
const MerchantSignIn = React.lazy(() => import('./component/common/MerchantSignIn'));
const CreateOrder = React.lazy(() => import('./component/shipments/CreateOrder'));
const PriceCalculator = React.lazy(() => import('./component/freight/PriceCalculator'));
const OrderDetails = React.lazy(() => import('./component/shipments/OrderDetails'));

function App() {
  let location = useLocation();
  const storedUserData = localStorage.getItem('user');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [userPermission, setUserPermission] = useState(null);
  useEffect(() => {
    if (storedUserData) {
      const parsedUser = JSON.parse(storedUserData);
      if (parsedUser?.permissions) {
        setUserPermission(JSON.parse(parsedUser?.permissions))
      }
      setUser(parsedUser);
    }
  }, [storedUserData]);
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <CommonContextProvider>
      <ThemeProvider theme={CustomTheme}>
        <div className="App">
          {location.pathname !== '/admin' && location.pathname !== '/login' &&
            <Sidebar open={sidebarOpen} onClose={toggleSidebar} />
          }
          <Suspense fallback={<Pageloader />}>
            <Routes>
              {user &&
                <Route path='*' element={<NotFound />} />
              }
              {user?.user_type !== "Merchant" &&
                <>
                  {(user) && (user?.id === 1 || userPermission?.Merchants?.add || userPermission?.Merchants?.list) &&
                    <Route exact path="/merchants" element={<Merchant />} />
                  }
                  {(user) && (user?.id === 1) &&
                    <Route exact path="/users" element={<UsersView />} />
                  }
                  {(user) && (user?.id === 1 || userPermission?.Couriers?.add || userPermission?.Couriers?.list) &&
                    <Route exact path="/couriers" element={<CourierView />} />
                  }
                  <Route path="/merchant-details" element={<SiteSetting />} />
                </>
              }
              {(user) && (user?.id === 1 || userPermission?.Dashboard?.view || user?.user_type === "Merchant") &&
                <Route exact path="/" element={<DashboardView />} />
              }
              <Route exact path="/admin" element={<AdminSignIn />} />
              <Route exact path="/login" element={<MerchantSignIn />} />
              {(user) && (user?.id === 1 || userPermission?.Orders?.add || userPermission?.Orders?.list || user?.user_type === "Merchant") &&
                <Route exact path="/orders" element={<ShipmentsView />} />
              }
              {(user) && (user?.id === 1 || userPermission?.Orders?.add || user?.user_type === "Merchant") &&
                <Route path="/orders/:createOrder" element={<CreateOrder />} />
              }
              <Route path="/price-calulator" element={<PriceCalculator />} />
              <Route path="/orders/details" element={<OrderDetails />} />
              <Route path="/merchants/pricing" element={<PricingView />} />
              {(user) && (user?.id === 1 || userPermission?.Merchant_Warehouse?.add || userPermission?.Merchant_Warehouse?.list || user?.user_type === "Merchant") &&
                <Route exact path="/warehouse" element={<PickupPointsView />} />
              }
              {(user) && (user?.id === 1 || userPermission?.Pickup_Requests?.add || userPermission?.Pickup_Requests?.list || user?.user_type === "Merchant") &&
                <Route exact path="/pickupRequest" element={<PickupView />} />
              }
              {(user) && (user?.id === 1 || userPermission?.Downloads?.list || user?.user_type === "Merchant") &&
                <Route exact path="/download" element={<DownloadView />} />
              }
              <Route exact path="/siteSetting" element={<SiteSetting />} />
              {(user) && (user?.id === 1 || userPermission?.Serviceability?.import || userPermission?.Serviceability?.export || userPermission?.Serviceability?.list || user?.user_type === "Merchant") &&
                <Route exact path="/serviceability" element={<ServiceabilityView />} />
              }
              {(user) && (user?.id === 1 || userPermission?.Serviceability?.import || userPermission?.Serviceability?.export || userPermission?.Serviceability?.list || user?.user_type === "Merchant") &&
                <Route exact path="/weight" element={<WeightScreenView />} />
              }
              {/* {(user) && (user?.id === 1 || userPermission?.Serviceability?.import || userPermission?.Serviceability?.export || userPermission?.Serviceability?.list || user?.user_type === "Merchant") && */}
              <Route exact path="/freight" element={<FreightView />} />
              <Route exact path="/invoice" element={<InvoiceView />} />
              <Route exact path="/ndr" element={<NdrView />} />
              {/* } */}
            </Routes>
          </Suspense>
          {!['/login', '/admin'].includes(location.pathname) && user?.user_type === "Merchant" && (
            <>
              <Footer />
            </>
          )}
        </div>
      </ThemeProvider>
    </CommonContextProvider>
  );
}

export default App;
